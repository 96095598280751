<template>
  <div class="RepreneurItem">
    <div class="row mt-4">
      <div class="col-12 col-md-6">
        <strong> Prénom </strong> : {{repreneur.user.first_name}}
      </div>
      <div class="col-12 col-md-6">
        <strong> Nom </strong> : {{repreneur.user.last_name}}
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <strong> Email </strong> : {{repreneur.user.username}}
      </div>
      <div class="col-12 col-md-6">
        <strong> Téléphone </strong> :
        <span v-if="repreneur.phone_number">
          {{repreneur.phone_number.match(/.{1,2}/g).join(' ')}}
        </span>
        <span v-else>
          Pas de numéro de téléphone renseigné
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <strong> Adresse </strong> : {{repreneur.address}}, {{repreneur.zip_code}} {{repreneur.city}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RepreneurItem',
  props: {
    repreneur: Object,
  }
}
</script>
